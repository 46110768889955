<template>
  <section
    v-if="data"
    no-body
  >
    <b-card
      v-for="item in data"
      :key="item.id"
      class="card-statistics"
    >
      <!-- :class="item.customClass"
       -->
      <!-- <b-card-header>
        <b-card-title>Statistics</b-card-title>
        <b-card-text class="font-small-2 mr-25 mb-0">
          Updated 1 month ago
        </b-card-text>
      </b-card-header> -->
      <b-card-text class="font-small-3 mb-1">
        {{ item.subtitle }}
      </b-card-text>
      <b-media no-body>
        <b-media-aside

          class="mr-2"
        >
          <b-avatar
            size="48"
            :variant="item.color"
          >
            <feather-icon
              size="24"
              :icon="item.icon"
            />
          </b-avatar>
        </b-media-aside>
      </b-media>
      <b-media-body class="my-auto">
          <h4 class="font-weight-bolder mt-1">
            {{ item.title }}
          </h4>
        </b-media-body>
    </b-card>
  </section>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
