<template>
  <section id="dashboard-ecommerce">
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <span><strong>Info:</strong> Sistem Informasi Penanganan Laporan Masyarakat. Lihat Panduan Penggunaan Aplikasi.</span>
      </div>
    </b-alert>
    
    <b-row class="match-height">
      <b-col
        xl="2"
        md="12"
      >
        <dashboard-statistics :data="data.statisticsItems" />
      </b-col>
      <b-col
        xl="10"
        md="10"
      >
        <b-row>
          <b-col
            md="6"
          >
            <dashboard-doughnut :apexChatData="apexChatData" /> 
          </b-col>
          <b-col
            md="6"
          >
            <dashboard-doughnut2  :apexChatData2="apexChatData2" /> 
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
      >
        <dashboard-list :data="data.transactionData" /> 
      </b-col>
      <b-col
        md="6"
      >
        <dashboard-list2 :data="data.transactionData2" /> 
      </b-col>
    </b-row>
  </section>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BAlert, BCardText, BRow, BCol, } from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import DashboardStatistics from './DashboardStatistics.vue'
import DashboardDoughnut from './DashboardDoughnut.vue'
import DashboardDoughnut2 from './DashboardDoughnut2.vue'
import DashboardList from './DashboardList.vue'
import DashboardList2 from './DashboardList2.vue'
import {
    getDataDashboard,
} from '@/connection-api/master'
import apexChatData from './apexChartData'
import apexChatData2 from './apexChartData2'

export default {
  components: {
    BCardCode,
    BAlert,
    BCardText,
    BRow,
    BCol,
    DashboardStatistics,
    DashboardDoughnut,
    DashboardDoughnut2,
    DashboardList,
    DashboardList2,
  },
  data() {
    return {
      data: {},
      dataDashboard: {},
      apexChatData,
      apexChatData2,
    }
  },
  async created() {
     // data
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const userData = getUserData()
        this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      })
      
    try {
      const response = await getDataDashboard()
      if(response.data.success === true)
      {
        this.data.statisticsItems[0].title = this.formatPrice(response.data.data.statistik.total_laporan)
        this.data.statisticsItems[1].title = this.formatPrice(response.data.data.statistik.total_laporan_selesai)
        this.apexChatData.donutChart.chartOptions.labels = response.data.data.donut.label_total_laporan_by_jenis_perkara
        this.apexChatData.donutChart.series = response.data.data.donut.series_total_laporan_by_jenis_perkara
        this.apexChatData2.donutChart.chartOptions.labels = response.data.data.donut.label_total_laporan_by_dugaan_pelanggaran
        this.apexChatData2.donutChart.series = response.data.data.donut.series_total_laporan_by_dugaan_pelanggaran

        //online
        this.data.transactionData[0].payment = response.data.data.rekap_online.diterima
        this.data.transactionData[1].payment = response.data.data.rekap_online.verifikasi
        this.data.transactionData[2].payment = response.data.data.rekap_online.verifikasi_pending
        this.data.transactionData[6].payment = response.data.data.rekap_online.pemantauan
        this.data.transactionData[7].payment = response.data.data.rekap_online.selesai


        //langsung
        this.data.transactionData2[0].payment = response.data.data.rekap_offline.diterima
        this.data.transactionData2[1].payment = response.data.data.rekap_offline.verifikasi
        this.data.transactionData2[2].payment = response.data.data.rekap_offline.verifikasi_pending
        this.data.transactionData2[6].payment = response.data.data.rekap_offline.pemantauan
        this.data.transactionData2[7].payment = response.data.data.rekap_offline.selesai
      }
    } catch (error) {
      // do nothing
    }
  },
  methods: {
    formatPrice(value) {
        let val = (value/1).toFixed(0).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
  },
}
</script>
